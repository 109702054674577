import React, { useLayoutEffect, useState } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import Modal from 'react-awesome-modal';
import { useCookies } from 'react-cookie';
import { Link } from 'gatsby';
import videoThumbnailImg from '../../images/removal-video-thumbnail-img.webp'
import './takeover-lightbox.scss';

const TakeOverModal = props => {

    const [cookies, setCookie] = useCookies(['takeover-modal-close']);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setCookie('takeover-modal-close', true, { path: '/', expires: undefined })
    }

    useLayoutEffect(() => {
        setOpen(!cookies['takeover-modal-close'])
    }, [cookies['takeover-modal-close']])

    return (
        <Modal visible={open} onClickAway={handleClose}>
            <div className="takeover-modal">
                <header>
                    <h1><strong>New!</strong> Peer-perspective video on implant removal</h1>
                </header>

                <div className="takeover-modal__content">
                    <Row middle='md'>
                        <Col md={5}>
                            <div className="content">
                                <p>Hear from Dr Jennifer Abuzzahab, pediatric endocrinologist, and Dr David Lanning, pediatric surgeon, as they share their decades-long experience with <nobr>SUPPRELIN<sup>®</sup> LA</nobr> (histrelin acetate).</p>
                            </div>
                        </Col>
                        <Col md={7}>
                            <img src={videoThumbnailImg} alt="Video Thumbnail" />
                        </Col>
                    </Row>
                </div>

                <div className="btn-wrap">
                    <Link
                        to='/hcp/caregiver-resources'
                        className='btn btn-primary'
                        onClick={handleClose}
                    >
                        Watch Now
                    </Link>
                </div>

                <div className="btn-close">
                    <button onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 50 50">
                            <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default TakeOverModal;