import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
// import EventEmitter from '../../../lib/emitter'
// import VideoCollection from '../../../lib/videos/hcp-videos'
import { navigate } from "gatsby"
import img1 from '../../../images/hero/hcp-hero-slider-img01.webp';
import img2 from '../../../images/hero/hcp-hero-slider-img02.png';
import img3 from '../../../images/hero/hcp-hero-slider-img03.webp';
import img4 from '../../../images/hero/hcp-hero-slider-img04.webp';
import img1Mobile from '../../../images/hero/hcp-hero-slider-mob-img01.webp';
import img2Mobile from '../../../images/hero/hcp-hero-slider-mob-img02.webp';
import img3Mobile from '../../../images/hero/hcp-hero-slider-mob-img03.webp';

import './index.scss'
// import {
//     isMobile
//   } from "react-device-detect";


import '../hero.scss'

const handleHero1Click = () => {
    navigate('/hcp/lh-clinical-data')
}
const handleHero2Click = () => {
    navigate('/hcp/support-center-and-savings')
}
const handleHero3Click = () => {
    navigate('/hcp/caregiver-resources')
    // EventEmitter.emit(`onVideoOpenEvent-${VideoCollection[1].wistiaID}`, true)
}
const handleHero4Click = () => {
    navigate('/hcp/lh-clinical-data')
}

//For customizations
//http://react-responsive-carousel.js.org/storybook/index.html

class Hero extends Component {
    render() {
        return (
            <Carousel emulateTouch autoPlay infiniteLoop dynamicHeight showThumbs={false} showStatus={false} showArrows={false} interval={6000}>
                <div onClick={handleHero1Click} onKeyDown={handleHero1Click}>
                    <img className='desktop-only for-desktop-only' src={img1} alt='SUPPRELIN LA prescribed by pediatric endocrinologists' />
                    <img className='mobile-only for-mobile-only' src={img1Mobile} alt='SUPPRELIN LA prescribed by pediatric endocrinologists' />
                </div>
                <div onClick={handleHero2Click} onKeyDown={handleHero2Click}>
                    <img className='desktop-only for-desktop-only' src={img2} alt='how the SUPPRELIN LA support center can assist with insurance coverage and access for you and your appropriate patients' />
                    <img className='mobile-only for-mobile-only' src={img2Mobile} alt='how the SUPPRELIN LA support center can assist with insurance coverage and access for you and your appropriate patients' />
                </div>
                <div onClick={handleHero3Click} onKeyDown={handleHero3Click}>
                    <img className='desktop-only for-desktop-only' src={img3} alt='discussing the implant insertion/removal procedure with caregivers' />
                    <img className='mobile-only for-mobile-only' src={img3Mobile} alt='discussing the implant insertion/removal procedure with caregivers' />
                </div>
                <div onClick={handleHero4Click} onKeyDown={handleHero4Click}>
                    <img className='desktop-only for-desktop-only' src={img4} alt='Treat CPP with 100% confidence' />
                    <img className='mobile-only for-mobile-only' src={img4} alt='Treat CPP with 100% confidence' />
                </div>
            </Carousel>
        );
    }
};

export default Hero;
