import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../components/dfa-theme/layout/hcp'
import BrandCTA from '../../components/brand-cta'
import Hero from '../../components/hero/hcp'
import VideoContainer from '../../components/video-container'
import HcpVideoCollection from '../../lib/videos/hcp-videos'
import TakeOverModal from '../../components/takeover-lightbox'

const metaTags = {
  description: 'HCPs can learn about SUPPRELIN® LA (histrelin acetate) safety and side effects, features and risks, including full Prescribing Information.',
  title: 'Home | Healthcare Professional',
  keywords: 'Homepage',
}

// const externalLinksMetaTags = {
//   category: 'External Link',
//   action: 'Click',
//   label: 'Deerfield Homepage',
// }

const reference = (
  <>
    <p><strong>Reference: 1.</strong> SUPPRELIN<sup>&reg;</sup> LA [package insert]. Endo USA, Inc.</p>
  </>
)
const IndexPage = () => (
  <>
    <Layout meta={metaTags} hasReferences={true} reference={reference}>
      <Row>
        <Col xs={12}>
          <Hero />
        </Col>
      </Row>

      <Row between="xs">
        <Col xs={12} md={6}>
          <BrandCTA
            href={'/hcp/downloads-office-support'}
            LinkCaption="Discover important reimbursement forms for your practice - hcp home"
          >
            Discover important reimbursement forms for <nobr>your practice</nobr>
          </BrandCTA>
        </Col>
        <Col xs={12} md={6}>
          <BrandCTA
            href="/hcp/safety-tolerability-profile"
            LinkCaption="View LH Suppression efficacy data for SUPPRELIN LA - hcp home"
          >
            See safety and tolerability profile for SUPPRELIN<sup>&reg;</sup> LA
          </BrandCTA>
        </Col>
      </Row>
    </Layout>
    
    <VideoContainer
      url={HcpVideoCollection[1].iframeUrl}
      smallThumb
      active
      className={'video-thumb'}
      carousel
      directLink={false}
      videoId={HcpVideoCollection[1].wistiaID}
      poster=''>
    </VideoContainer>

    <TakeOverModal />

  </>
)

export default IndexPage
